<template>
  <main class="page-container container">
    <header
      class="page__header"
      :class="[
        isMobileMedium ? 'flex-wrap' : '',
        isMobileSmall ? 'mb-10' : 'mb-30',
      ]"
    >
      <app-text
        tag="h1"
        size="26"
        line-height="34"
        weight="600"
        :class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
        class="mr-30 font_Hsemibold"
        >{{ $t("normativeActs") }}
      </app-text>
    </header>

    <div class="section__wrapper" :class="isMobileSmall ? 'mb-40' : 'mb-80'">
      <div class="normative-acts__item">
        <AppText size="16" weight="600" class="mb-20 font_Hsemibold">
          {{ $t("LawsOfUzb") }}
        </AppText>
        <ul class="custom-list">
          <li class="custom-list__item">
            <!--            <router-link to="/" class="custom-list__link">-->
            <!--              О государственных закупках-->
            <!--            </router-link>-->

            <a
              :href="`/docs/normative-acts/o-gosudarstvennix-zakupkax-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link font_Hmedium">
                  {{ $t("stateProcurements") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-auto">
                  67 kB
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="normative-acts__item">
        <AppText size="16" weight="600" class="mb-20 font_Hsemibold">
          {{ $t("ResolutionsOfThePresident") }}:
        </AppText>
        <ul class="custom-list">
          <li class="custom-list__item">
            <a
              :href="`/docs/normative-acts/4544-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link font_Hmedium">
                  {{ $t("docText4544") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-5">25 kB</div>
              </div>
            </a>
          </li>
          <li class="custom-list__item">
            <a
              :href="`/docs/normative-acts/3953-${$store.state.clng}.doc`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link">
                  {{ $t("docText3953") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-5">25 kB</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="normative-acts__item">
        <AppText size="16" weight="600" class="mb-20 font_Hsemibold">
          {{ $t("DecreesOfThePresident") }}:
        </AppText>
        <ul class="custom-list">
          <!--
          <li class="custom-list__item">
            <router-link to="/" class="custom-list__link">
              О дополнительных мерах по совершенствованию системы
              противодействия коррупции в Республике Узбекистан
            </router-link>
          </li>
          -->
        </ul>
      </div>
      <div class="normative-acts__item">
        <!--
        <AppText size="16" weight="600" class="mb-20 font_Hsemibold"
          >{{ $t("provisions") }}:
        </AppText>
        -->
        <ul class="custom-list">
          <li class="custom-list__item">
            <a
              :href="`/docs/normative-acts/3013-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link">
                  {{ $t("docText3013") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-5">21 kB</div>
              </div>
            </a>
          </li>
          <li class="custom-list__item">
            <a
              :href="`/docs/normative-acts/3057-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link">
                  {{ $t("docText3057") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-5">21 kB</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

        <div class="normative-acts__item">
        <AppText size="16" weight="600" class="mb-20 font_Hsemibold">
          {{ $t("decreesofTheCabinetOFMinisters") }}:
        </AppText>
        <ul class="custom-list">
          <!--
          <li class="custom-list__item">
            <router-link to="/" class="custom-list__link">
              О дополнительных мерах по совершенствованию системы
              противодействия коррупции в Республике Узбекистан
            </router-link>
          </li>
          -->
          <li class="custom-list__item">
             <a
              :href="`/docs/normative-acts/833-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link">
                  {{ $t("docText833") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-5">37 kB</div>
              </div>
            </a>
          </li>

          <li class="custom-list__item">
             <a
              :href="`/docs/normative-acts/276-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                
                <span class="custom-list__link">
                  {{ $t("docText276") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-5">68 kB</div>
              </div>
            </a>
          </li>
          
        </ul>
      </div>
      <div class="normative-acts__item">
        <AppText size="16" weight="600" class="mb-20 font_Hsemibold"
          >{{ $t("provisions") }}:
        </AppText>
         <ul class="custom-list">
          <li class="custom-list__item">
            <a
              :href="`/docs/normative-acts/rules-of-ethical-conduct-${$store.state.clng}.docx`"
              download
            >
              <div class="d-flex file-container">
                <div class="file-icon mr-10">
                   
                </div>
                <span class="custom-list__link font_Hmedium">
                  {{ $t("docTextEthical") }}
                </span>
                <div class="file-size color-text-light fs-12 ml-auto">{{$t("docTextEthicalSize")}}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";

export default {
  name: "index",
  components: { AppText },
};
</script>

<style lang="scss" scoped>
.normative-acts {
  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.file-container {
  align-items: center;
}

.file-size {
  white-space: nowrap;
}

.custom-list {
  &__link{
    font-size: 14px;
    &:hover{
      color: var(--color-main2);
    }
  }
}
</style>
